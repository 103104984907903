import { Branch } from '../../../dorian-shared/types/branch/Branch';
import { StepTypes } from '../../../dorian-shared/types/branch/BranchStep';

export function createNodeTree(nodesToReposition: Branch[]) {
  const tree: { [key: number]: number[] } = {};
  nodesToReposition.forEach((branch) => {
    if (branch.gotoBranchId) {
      tree[branch.id] = [branch.gotoBranchId];
      return;
    }
    if (branch.steps?.[0].stepTypeId === StepTypes.Choice) {
      tree[branch.id] = branch.steps[0].answers?.map((answer) => answer.gotoBranchId) ?? [];
      return;
    }
    tree[branch.id] = [];
  });
  return tree;
}
