import { useField } from 'formik';
import React from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import classes from './AchievementField.module.scss';

export type AchievementFieldProps = React.InputHTMLAttributes<HTMLInputElement> & FormControlProps & {
  id: string;
  label?: string;
  className?: string;
  _ref?: React.Ref<HTMLInputElement>;
  children?: React.ReactNode;
};

export function AchievementField(props: AchievementFieldProps) {
  const {
    id,
    label = '',
    children,
    className,
    _ref,
    ...rest
  } = props;

  const [field, meta] = useField(id);

  return (
    <Form.Group className={className}>
      <Form.Label
        htmlFor={id}
        className={classes.label}
      >
        {label}
      </Form.Label>
      <Form.Control
        id={id}
        ref={_ref}
        {...field}
        {...rest}
      >
        {children}
      </Form.Control>
      {meta.error && (
        <div className="d-block invalid-tooltip">
          {meta.error}
        </div>
      )}
    </Form.Group>
  );
}
