import {
    BranchList,
    getMemoriesByStoryFlow,
    MemoryCheck,
    MemoryEconomy,
    MemoryGrant,
    MemoryGrantType,
    type StoryFlow,
    type StoryFlowDraft
} from './AnalyseStoryFlowsEconomyManager';
import {Branches, MemoryBank, StepType, Story} from './bookEconomy';
import {applyMemoryAction, evaluateCheck, selectBranchFromSwitches} from './index';

export const isAnswerPaid = (type: string): boolean => ['erotic', 'romantic', 'paid'].includes(type);

export const iterateFlows = (
    story: Story,
    storyBranches: Branches,
    affectionPointsMemories: string[],
    initialMemoryBank: MemoryBank
): StoryFlow[] => {
    if (storyBranches.intro === undefined) {
        return [];
    }

    let queue: StoryFlowDraft[] = [];
    const final: StoryFlowDraft[] = [];
    queue.push({
        branches: ['intro'],
        memoryBank: initialMemoryBank,
        paid: false,
        isFinal: false,
        isDead: false,
    });
    let iter = 0;

    do {
        if (iter % 1000 === 0) {
            // clean memory
            queue = queue.filter(flow => !flow.isDead && !flow.isFinal);
        }
        const flowsToProcess = queue.filter(flow => !flow.isFinal && !flow.isDead);
        for (const flow of flowsToProcess) {
            iter++;
            // if (iter % 100000 === 0) {
            //     console.log(`iteration: ${iter}, queue size: ${queue.length}`);
            // }
            const branchName = flow.branches[flow.branches.length - 1];
            const branch = storyBranches[branchName];
            if (!branch) {
                flow.isDead = true;
                continue;
            }
            const firstStep = branch.steps[0];
            if (!firstStep) {
                flow.isDead = true;
                continue;
            }

            switch (firstStep.type) {
                case StepType.Ending:
                    flow.isFinal = true;
                    final.push(flow);
                    continue;
                case StepType.Choice: {
                    const answers = firstStep.answers;
                    if (!answers) {
                        flow.isDead = true;
                        continue;
                    }
                    const nextBranches = answers.map(answer => {
                        const requirement = answer.requirement;
                        if (requirement?.check) {
                            if (!evaluateCheck(flow.memoryBank, requirement.check)) {
                                return undefined;
                            }
                        }
                        const branch = answer.goto?.branch;
                        if (branch && storyBranches[branch]) {
                            const paid = isAnswerPaid(answer.type);
                            return {branch, paid};
                        }
                        return undefined;
                    })
                        .filter(br => br !== undefined && !flow.branches.includes(br.branch)) as {branch: string, paid: boolean}[];

                    const newFlows: StoryFlowDraft[] = nextBranches
                        .map(({branch, paid}) => ({
                            branches: [...flow.branches, branch],
                            memoryBank: {...flow.memoryBank},
                            paid: flow.paid || paid,
                            isFinal: false,
                            isDead: false,
                        }));

                    if (newFlows.length > 0) {
                        queue.push(...newFlows);
                    }
                    flow.isDead = true;
                    continue;
                }
                case StepType.Check: {
                    const {switch: switches, check} = firstStep;
                    if (!switches || !check) {
                        flow.isDead = true;
                        continue;
                    }

                    const nextBranchName = selectBranchFromSwitches(flow.memoryBank, check, switches);
                    if (nextBranchName && storyBranches[nextBranchName] && !flow.branches.includes(nextBranchName)) {
                        flow.branches.push(nextBranchName);
                    } else {
                        flow.isDead = true;
                    }
                    continue;
                }
            }

            for (const step of branch.steps) {
                if (step.type === StepType.Remember) {
                    if (step.action) {
                        applyMemoryAction(flow.memoryBank, step.action);
                    }
                }
            }

            const lastStep = branch.steps[branch.steps.length - 1];
            const nextBranch = lastStep.goto?.branch;
            if (nextBranch && !flow.branches.includes(nextBranch)) {
                flow.branches.push(nextBranch);
            } else {
                flow.isDead = true;
            }
        }
    } while (queue.filter(flow => !flow.isFinal && !flow.isDead).length > 0);

    return final
        .map<StoryFlow>(({memoryBank, paid, branches}) => {
            const flows: BranchList = branches.map(branch => storyBranches[branch]);
            const memories = getMemoriesByStoryFlow(story, flows, affectionPointsMemories);
            return {
                memoryBank,
                memories,
                paid,
                flows,
            };
        });
};
