import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useApiService } from 'contexts/ApiServiceContext/ApiServiceContext';
import { useMemoryBankServiceContext } from '../../../contexts/MemoryBankContext/MemoryBankServiceContext';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { StoryState } from '../../../dorian-shared/types/story/Story';
import { bugTracker } from '../../../services/bugTracker/BugTrackerService';
import { auth } from '../../Auth/Auth';
import { PageWrapper } from '../../ui/PageWrapper';
import { showToast } from '../../ui/utils';
import { isMemoryBankEnabled } from '../../utils/isMemoryBankEnabled';
import { MemoryDTO, MemoryType } from '../Book/MemoryBank/memoryBankTypes';
import { BookEconomyFileLoader } from './BookEconomyFileLoader/BookEconomyFileLoader';
import { BookEconomySettings } from './BookEconomySettings/BookEconomySettings';
import { BookEconomyTabs } from './BookEconomyTabs';
import { calculateFullBookEconomyData } from './economics';
import { MemoriesStorySummary, TotalMemoryEconomy } from './economics/AnalyseStoryFlowsEconomyManager';
import { Book } from './economics/bookEconomy';
import { BookEconomyStoryManager } from './economics/BookEconomyStoryManager';

import { getBookEconomyPage } from './utils';

export function BookEconomyPage() {
  const [bookManager, setBookManager] = useState<BookEconomyStoryManager | null>(null);
  const [memoryBank, setMemoryBank] = useState<MemoryDTO[]>([]);
  const [memoriesEconomyStats, setMemoriesEconomyStats] = useState<Record<number, TotalMemoryEconomy> | null>(null);
  const [memoriesStorySummary, setMemoriesStorySummary] = useState<MemoriesStorySummary | null>(null);
  const [affectionPointsList, setAffectionPointsList] = useState<string[] | null>(null);

  const [,
    {
      isLoading: isExtracting,
      isSuccess: isExtractSuccess,
      isError: isExtractError,
      setToError: setToExtractError,
      setToLoading: setToExtracting,
      setToSuccess: setToExtractSuccess,
      statusMessage: extractStatusMessage,
    },
  ] = useAsyncOperationState();

  const [,
    {
      isLoading,
      isSuccess,
      isError,
      setToError,
      setToLoading,
      setToSuccess,
      statusMessage,
    },
  ] = useAsyncOperationState();

  const { id: bookId } = useParams<{ id: string }>();

  const apiService = useApiService();
  const history = useHistory();

  const user = auth.getUser();
  const memoryBankService = useMemoryBankServiceContext();

  const economyPage = getBookEconomyPage(bookId);

  useEffect(() => {
    if (!bookManager) {
      return;
    }

    setToExtracting('Extracting data...');
    calculateFullBookEconomyData(bookManager)
        .then(({ totalEpisodesSummary, affectionPointsMemories, staticsPerStory }) => {
          setMemoriesStorySummary(totalEpisodesSummary);
          setAffectionPointsList(affectionPointsMemories);
          setMemoriesEconomyStats(staticsPerStory);
          setToExtractSuccess('Data extracted successfully');
        })
        .catch((error) => setToExtractError(error.message));
  }, [bookManager, setToExtractError, setToExtractSuccess, setToExtracting]);

  useEffect(() => {
    memoryBankService.fetchMemoryBank(bookId)
      .then((response) => {
        const memoryBankFiltered = response.filter((memory) => memory.type === MemoryType.Number);
        setMemoryBank(memoryBankFiltered);
      })
      .catch((error) => {
        showToast({ textMessage: 'Error loading memory bank' });
        if (error instanceof Error) {
          bugTracker().reportError(error);
        } else {
          bugTracker().reportError(new Error('Error loading memory bank'));
        }
      });
  }, [bookId, memoryBankService]);

  const handleLoad = async (storyState: StoryState) => {
    setToLoading('Loading story episodes...');
    try {
      const response = await apiService.fetchBookEconomy(bookId, storyState);
      const newBookEconomyStoryManager = new BookEconomyStoryManager(response);
      setBookManager(newBookEconomyStoryManager);
      setToSuccess('Story episodes loaded successfully');
    } catch (error) {
      setToError('Error loading story episodes');
      showToast({ textMessage: 'Error loading story episodes' });
      if (error instanceof Error) {
        bugTracker().reportError(error);
      } else {
        bugTracker().reportError(new Error('Error loading story episodes'));
      }
    }
  };

  const handleFileLoad = (bookEconomy: Book) => {
    setToLoading('Loading story episodes...');
    try {
      const newBookEconomyStoryManager = new BookEconomyStoryManager(bookEconomy);
      setBookManager(newBookEconomyStoryManager);
      setToSuccess('Story episodes loaded successfully');
    } catch (error) {
      setToError('Error loading story episodes');
      showToast({ textMessage: 'Error loading story episodes' });
      if (error instanceof Error) {
        bugTracker().reportError(error);
      } else {
        bugTracker().reportError(new Error('Error loading story episodes'));
      }
    }
  };

  if (!user || !isMemoryBankEnabled(user)) {
    history.push('/');
    return null;
  }

  return (
    <PageWrapper
      page={economyPage}
    >
      <Alert variant="warning">
        <Alert.Heading>Warning:</Alert.Heading>
        This functionality is in the alpha stage, and there may be some inaccuracies or errors. We are working on
        improvements and appreciate your understanding!
      </Alert>
      <BookEconomySettings
        onLoad={handleLoad}
        memoryBank={memoryBank}
      />
      {user?.role === 'admin' && (
        <BookEconomyFileLoader onLoad={handleFileLoad} />
      )}
      {isLoading && !isExtracting && !isExtractSuccess && (<Alert variant="info">{statusMessage}</Alert>)}
      {isSuccess && !isExtractSuccess && (<Alert variant="success">{statusMessage}</Alert>)}
      {isError && (<Alert variant="danger">{statusMessage}</Alert>)}
      {isExtracting && (<Alert variant="info">{extractStatusMessage}</Alert>)}
      {isExtractSuccess && (<Alert variant="success">{extractStatusMessage}</Alert>)}
      {isExtractError && (<Alert variant="danger">{extractStatusMessage}</Alert>)}
      {!bookManager && (<Alert>No data to show</Alert>)}
      {bookManager && isSuccess && isExtractSuccess && (
        <BookEconomyTabs
          memoriesEconomyStats={memoriesEconomyStats}
          memoriesStorySummary={memoriesStorySummary}
          affectionPointsList={affectionPointsList}
          bookId={bookId}
        />
      )}
    </PageWrapper>
  );
}
