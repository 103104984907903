import {
    AnalyseStoryFlowsEconomyManager,
    type MemoriesStorySummary,
    type MemoryEconomyStats,
    type MemoryStorySummary,
    type TotalMemoryEconomy,
    type StoryFlow,
} from './AnalyseStoryFlowsEconomyManager';
import {
    type AuxBookVariableValue,
    type BookVariableValue,
    type MemoryBank,
    type StepAction,
    type StepCheck,
    type StepSwitch,
} from './bookEconomy';
import {BookEconomyStoryManager} from './BookEconomyStoryManager';
import {iterateFlows} from './flowIteration';
import {arrayChunks} from '../helpers/array';
import { logger } from '../../../../services/loggerService/loggerService';

export const evaluateCheck = (memoryBank: MemoryBank, check: StepCheck): BookVariableValue => {
    const value = check.value;

    switch (check.operator) {
        case "equal":
            return memoryBank?.[check.variable] === check.value;
        case "notEqual":
            return memoryBank?.[check.variable] !== check.value;
        case "atLeast":
            return memoryBank?.[check.variable] >= check.value;
        case "atMost":
            return memoryBank?.[check.variable] <= check.value;
        case "greater":
            return memoryBank?.[check.variable] > check.value;
        case "less":
            return memoryBank?.[check.variable] < check.value;
        case 'min': {
            const values = Object.fromEntries((value as AuxBookVariableValue).variable.map(v => [v, memoryBank[v]]));
            const min = Math.min(...(Object.values(values) as number[]));
            for (const [name, value] of Object.entries(values)) {
                if (value === min) {
                    return name;
                }
            }
        }
        case 'max': {
            const values = Object.fromEntries((value as AuxBookVariableValue).variable.map(v => [v, memoryBank[v]]));
            const max = Math.min(...(Object.values(values) as number[]));
            for (const [name, value] of Object.entries(values)) {
                if (value === max) {
                    return name;
                }
            }
        }
        default:
            console.error(check);
            throw new Error(`unknown operator: ${check.operator}`);
    }
};

export const selectBranchFromSwitches = (memoryBank: MemoryBank, check: StepCheck, switches: StepSwitch[]) => {
    const calcValue = evaluateCheck(memoryBank, check);

    for (const stepSwitch of switches) {
        const {value, goto} = stepSwitch;
        if (calcValue === value) {
            return goto.branch;
        }
    }

    return null;
};
export const applyMemoryAction = (memoryBank: MemoryBank, action: StepAction) => {
    const {variable, type, value} = action;

    if (memoryBank?.[variable] === undefined) {
        return;
    }

    let memValue = memoryBank[variable];

    switch (type) {
        case 'increase':
            if (typeof memValue === 'number' && typeof value === 'number') {
                memValue += value;
            }
            break;
        case 'decrease':
            if (typeof memValue === 'number' && typeof value === 'number') {
                memValue -= value;
            }
            break;
        case 'set':
            memValue = value;
            break;
    }

    memoryBank[variable] = memValue;
};

export const copySelectedProps = (memoryBank: MemoryBank, props: string[]) => {
    const result: MemoryBank = {};
    for (const prop of props) {
        result[prop] = memoryBank[prop];
    }
    return result;
};

export type FullBookEconomyData = {
    totalEpisodesSummary: MemoriesStorySummary;
    affectionPointsMemories: string[];
    staticsPerStory: Record<number, TotalMemoryEconomy>;
}

export const calculateFullBookEconomyData = async (bookManager: BookEconomyStoryManager): Promise<FullBookEconomyData> => {
    const staticsPerStory: Record<number, TotalMemoryEconomy> = {};
    let totalCalcTime = 0;
    const calcResults: { name: string, flows: number, 'calc time': string }[] = [];
    const initialMemoryBank: MemoryBank = Object.fromEntries(
        Object.entries(bookManager.getVariables())
            .map(([name, data]) => [name, data.defaultValue])
    );
    let memoryBanks: MemoryBank[] = [initialMemoryBank];
    const affectionPointsMemories = Object.entries(bookManager.getVariables())
        .filter(([name, data]) => name.toLowerCase().includes('affection') || data.displayName.length > 0)
        .map(([name]) => name);
    const affectionPointsUsed = new Set<string>();

    // console.log('initialMemoryBank', initialMemoryBank);
    // console.log('affectionPointsMemories', affectionPointsMemories);

    for (const story of bookManager.stories) {
        const startTime = Date.now();
        const branches = bookManager.getBranchesByStoryId(story.id);
        if (!branches) {
            logger.error(`No branches found for story ${story.id}`);
            continue;
        }
        const storyFlowsManager = new AnalyseStoryFlowsEconomyManager();
        console.log(`===[ Start a new story flow from: ${story.title} ]===`);
        const startBranch = branches.intro;
        if (!startBranch) {
            logger.error('No intro branch found');
            continue;
        }

        const tempStoryFlows: StoryFlow[] = [];
        for (const memoryBank of memoryBanks) {
            const storyFlows = iterateFlows(story, branches, affectionPointsMemories, memoryBank);

            for (const chunk of arrayChunks(storyFlows, 1000)) {
                tempStoryFlows.push(...chunk);
            }
        }

        storyFlowsManager.storyFlows = tempStoryFlows;
        const memoriesEconomyStats = storyFlowsManager.calculateEconomyStats();
        const memoriesCriticalFlows = storyFlowsManager.getCriticalFlows(memoriesEconomyStats);
        staticsPerStory[story.chapter] = {
            stats: memoriesEconomyStats,
            flows: memoriesCriticalFlows,
        };

        // add APs which were used in this episode to the list of all APs used since ep.1
        for (const memory of Object.keys(memoriesEconomyStats)) {
            affectionPointsUsed.add(memory);
        }

        const newMemoryBanks: MemoryBank[] = [];

        for (const memory of affectionPointsUsed) {
            // building the maximum path for this affection point
            // console.log(`building memory bank focused on max %c${memory}`, 'color:#ff8080');
            const newMemoryBank: MemoryBank = {};
            // ensuring we have all memories included
            for (const [key, value] of Object.entries(initialMemoryBank)) {
                const apMemoryStats = memoriesEconomyStats[key];
                if (key === memory) {
                    newMemoryBank[key] = Object.values(staticsPerStory)
                        .map(chapter => Math.max(
                            chapter.stats?.[memory]?.freeModeMaxPoints ?? 0,
                            chapter.stats?.[memory]?.paidModeMaxPoints ?? 0
                        ))
                        .reduce((acc, curr) => acc + curr, initialMemoryBank[memory] as number);
                } else {
                    if (typeof initialMemoryBank[key] !== 'number') {
                        // not a number
                        newMemoryBank[key] = initialMemoryBank[key];
                    } else {
                        // get minimum value
                        newMemoryBank[key] = Object.values(staticsPerStory)
                            .map(chapter => chapter.stats?.[memory]?.minPoints ?? 0)
                            .reduce((acc, curr) => acc + curr, initialMemoryBank[memory] as number);
                    }
                }
            }
            // console.log('NEW MEMORY BANK', copySelectedProps(newMemoryBank, [...affectionPointsUsed]));
            newMemoryBanks.push(newMemoryBank);
        }
        memoryBanks = newMemoryBanks;

        const calcTime = Date.now() - startTime;
        calcResults.push({
            name: story.title,
            'flows': storyFlowsManager.storyFlows.length,
            'calc time': `${(calcTime / 1000).toFixed(2)}s`,
        });
        totalCalcTime += calcTime;
        await Promise.resolve();
    }

    const totalEpisodesSummary = Object.values(staticsPerStory)
        .map(episodeStats => episodeStats.stats)
        .reduce<MemoriesStorySummary>((acc, item) => {
            for (const [memory, memStats] of Object.entries(item)) {
                const accMemory: MemoryStorySummary = acc[memory] ?? {
                    freeModeMaxPoints: 0,
                    paidModeMaxPoints: 0,
                    minPoints: 0,
                };

                accMemory.freeModeMaxPoints! += memStats.freeModeMaxPoints;
                accMemory.paidModeMaxPoints! += memStats.paidModeMaxPoints;
                accMemory.minPoints! += memStats.minPoints;
                acc[memory] = accMemory;
            }

            return acc;
        }, {});
    // console.log('episodes stats', totalEpisodesSummary);

    calcResults.push({
        name: 'total',
        flows: 0,
        'calc time': `${(totalCalcTime / 1000).toFixed(2)}s`,
    });
    console.table(calcResults);

    return {
        totalEpisodesSummary,
        affectionPointsMemories,
        staticsPerStory,
    };
};

export const propToField: [string, keyof MemoryEconomyStats][] = [
    ['MAX FREE POINTS', 'freeModeMaxPoints'],
    ['MAX PREMIUM POINTS', 'paidModeMaxPoints'],
    ['MIN POINTS', 'minPoints'],

    ['MIN - DECREASE GRANTS', 'minDecreaseGrants'],
    ['MIN - DECREASE POINTS', 'minDecreasePoints'],

    ['MAX FREE - TOTAL GRANTS', 'freeModeMaxGrants'],
    ['MAX PREMIUM - TOTAL GRANTS', 'paidModeMaxGrants'],
    ['MIN - TOTAL GRANTS', 'minTotalGrants'],

    ['MAX PREMIUM - FREE GRANTS', 'paidModeMaxFreeGrants'],
    ['MIN - FREE GRANTS', 'freeModeMinGrants'],

    ['MAX PREMIUM - PREMIUM GRANTS', 'paidModeMaxPaidGrants'],
    ['MAX PREMIUM - FREE NESTED GRANTS', 'paidModeMaxFreeNestedGrants'],

    ['MAX FREE ROUTE - CHECKS', 'maxFreeModeMaxChecks'],
    ['MAX PREMIUM ROUTE - CHECKS', 'paidModeMaxChecks'],

    ['MIN CHECK POINT AMOUNT', 'minChecks'],
    ['MAX CHECK POINT AMOUNT', 'maxChecks'],
];
