import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Modal, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useApiService } from '../../../contexts/ApiServiceContext/ApiServiceContext';
import { useBookLocationsContext } from '../../../contexts/BookLocationsContext/BookLocationsContext';
import { RequestState, useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { AnswerStep, AnswerTypeId } from '../../../dorian-shared/types/branch/AnswerStep';
import { Branch } from '../../../dorian-shared/types/branch/Branch';
import { BranchStep } from '../../../dorian-shared/types/branch/BranchStep';
import { Character } from '../../../dorian-shared/types/character/Character';
import { showToast } from '../../ui/utils';
import { useImportTextColumns } from './hooks/useImportTextColumns';
import css from './ImportTextModal.module.scss';
import { EpisodeTextImport } from './Models/EpisodeTextImport';
import { EpisodeTextImportNode } from './Models/EpisodeTextImportNode';
import { EpisodeTextImportUtils } from './Models/EpisodeTextImportUtils';
import { createNodeTree } from './textImportUtils';
import { EpisodeTextColumnKey, EpisodeTextEntityType, EpisodeTextImportCommandKey } from './types/types';

type ImportTextModalProps = {
  bookId: number;
  episodeId: number;
  nodes: Branch[],
  onCancel: () => void;
  onSuccess: (nodeId: number) => void;
}

export function ImportTextModal(props: ImportTextModalProps) {
  const {
    bookId, episodeId, onCancel, onSuccess, nodes,
  } = props;
  const [episodeTextImport, setEpisodeTextImport] = useState<EpisodeTextImport>();
  const [text, setText] = useState<string>('');
  const [tableData, setTableData] = useState<any[]>([]);
  const [characters, setCharacters] = useState<Character[] | null>(null);

  const apiService = useApiService();
  const { bookLocations, fetchBookLocations } = useBookLocationsContext();

  useEffect(() => {
    if (!bookLocations) {
      fetchBookLocations(bookId);
    }
  }, [bookId, bookLocations, fetchBookLocations]);

  const [
    requestStatus,
    {
      setToError,
      setToLoading,
      setToSuccess,
    },
  ] = useAsyncOperationState();

  useEffect(() => {
    setToLoading();

    apiService.fetchCharactersByBookId(bookId)
      .then((fetchedCharacters) => {
        setCharacters(fetchedCharacters);
        setToSuccess();
      })
      .catch((error) => {
        console.error('[ImportTextModal] Error in fetching characters', error);
        showToast({ textMessage: 'Error in fetching characters' });
        setToError('Error in fetching characters');
      });
  }, [apiService, bookId, setToError, setToLoading, setToSuccess]);

  const handleCancelButton = () => {
    onCancel();
  };

  async function createEpisodeNodes(episodeTextImportNodes: EpisodeTextImportNode[]) {
    const lowestNodeY = EpisodeTextImportUtils.getLowestYInNodes(nodes);
    let nodePosition = {
      x: 1000,
      y: lowestNodeY + 350,
    };

    const createdNodes: Branch[] = [];

    for (let nodeIndex = 0; nodeIndex < episodeTextImportNodes.length; nodeIndex++) {
      const node = episodeTextImportNodes[nodeIndex];
      const nodeTitle = EpisodeTextImportUtils.removeAllCommandsFromString(node.title);
      const nodeDescription = EpisodeTextImportUtils.removeAllCommandsFromString(node.description);

      // set position by default
      nodePosition = {
        ...nodePosition,
        y: nodePosition.y + 350,
      };

      const locationCommand = node.commands.get(EpisodeTextImportCommandKey.Location);
      const locationName = locationCommand?.get(EpisodeTextColumnKey.Description) as string ?? '';
      const locationId = bookLocations?.find((el) => el.title.toLocaleLowerCase() === locationName.toLowerCase())?.id;

      const isIntroNode = node.title.toLocaleLowerCase() === 'intro';
      if (isIntroNode) {
        const introNode = nodes.find((el) => el.title.toLocaleLowerCase() === 'intro');
        if (introNode) {
          const newIntroNode = {
            ...introNode,
            description: nodeDescription,
            locationId: locationId ?? introNode.locationId,
          };
          createdNodes.push(newIntroNode);
        }
      } else {
        const nodeToCreate = {
          number: 1,
          title: nodeTitle,
          description: nodeDescription,
          locationId,
          ...nodePosition,
        };
        try {
          const newBranch: Partial<Branch> = await apiService.createEpisodeNode(episodeId, nodeToCreate);
          if (newBranch) {
            createdNodes.push(newBranch as Branch);
          } else {
            showToast({ textMessage: `Error in creating branch: ${node.title}` });
            console.error(`[ImportTextModal] Error in creating branch${node.title}`);
          }
        } catch (error) {
          showToast({ textMessage: `Error in creating branch: ${node.title}` });
          console.error(`[ImportTextModal] Error in creating branch: ${node.title}`, error);
        }
      }
    }
    return createdNodes;
  }

  async function updateEpisodeNodes(createdNodes: Branch[], episodeTextImportNodes: EpisodeTextImportNode[]) {
    const updatedNodes: Branch[] = [];
    const lowestNodeY = EpisodeTextImportUtils.getLowestYInNodes(nodes);
    let firstUnlinkedNodePositionY = lowestNodeY + 350;

    for (let nodeIndex = 0; nodeIndex < createdNodes.length; nodeIndex++) {
      const createdNode = createdNodes[nodeIndex];
      const isLastNode = nodeIndex === createdNodes.length - 1;

      let nodeToUpdate: Partial<Branch> = {
        id: createdNode.id,
        title: createdNode.title,
        description: createdNode.description,
        locationId: createdNode.locationId,
      };
      let stepToCreate: Partial<BranchStep> = {};

      const nodeToCreate = episodeTextImportNodes.find(
        (el) => el.title.toLocaleLowerCase() === createdNode.title.toLocaleLowerCase(),
      );
      if (!nodeToCreate) {
        console.error(`[ImportTextModal] Node not found: ${createdNode.title}`);
        continue;
      }

      const { steps } = nodeToCreate;

      if (steps.length === 0 && !isLastNode) {
        const nextNode = createdNodes[nodeIndex + 1];
        nodeToUpdate = {
          ...nodeToUpdate,
          gotoBranchId: nextNode.id,
        };
      }

      const stepsToCreate: Partial<BranchStep>[] = [];
      for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
        const step = steps[stepIndex];
        const isLastStep = stepIndex === steps.length - 1;

        const stepTypeId = EpisodeTextImportUtils.getStepTypeIdByEntityType(step.type);
        const speakerName = EpisodeTextImportUtils.removeAllCommandsFromString(step.speakerName);
        const speakerText = EpisodeTextImportUtils.removeAllCommandsFromString(step.speakerText);
        const description = EpisodeTextImportUtils.removeAllCommandsFromString(step.description);

        switch (step.type) {
          case EpisodeTextEntityType.Dialogue: {
            const stepCharacterName = speakerName.toLocaleLowerCase();
            const character = characters?.find((el) => el.alias.toLocaleLowerCase() === stepCharacterName);
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: speakerText,
              characterId: character?.id,
            };
            break;
          }
          case EpisodeTextEntityType.GotoFromNode: {
            const gotoNodeCommand = step.commands.get(EpisodeTextImportCommandKey.Goto);
            const toNodeTitle = String(gotoNodeCommand?.values().next().value ?? '');
            const toNode = createdNodes.find((el) => el.title.toLocaleLowerCase() === toNodeTitle.toLocaleLowerCase());
            if (!toNode) {
              console.error('[ImportTextModal] To node not found');
              continue;
            }
            nodeToUpdate = {
              ...nodeToUpdate,
              gotoBranchId: toNode.id,
            };
            continue;
          }
          case EpisodeTextEntityType.Choice: {
            const stepCharacterName = speakerName.toLocaleLowerCase();
            const character = characters?.find((el) => el.alias.toLocaleLowerCase() === stepCharacterName);

            const answers: Partial<AnswerStep>[] = [];
            let answerStepIndex = stepIndex + 1;
            let nextStepAnswer = steps[answerStepIndex];

            while (nextStepAnswer?.type === EpisodeTextEntityType.Answer) {
              const answerText = EpisodeTextImportUtils.removeAllCommandsFromString(nextStepAnswer.speakerText);
              const gotoNodeCommand = nextStepAnswer.commands.get(EpisodeTextImportCommandKey.Goto);
              const gotoBranchName = gotoNodeCommand?.values().next().value;
              const gotoBranchId = gotoBranchName
                ? createdNodes.find((el) => el.title.toLocaleLowerCase() === gotoBranchName.toLowerCase())?.id
                : undefined;

              answers.push({
                text: answerText,
                answerTypeId: AnswerTypeId.Free,
                answerPrice: 0,
                gotoBranchId,
              });
              answerStepIndex += 1;
              nextStepAnswer = steps[answerStepIndex];
            }
            stepIndex = answerStepIndex - 1;
            const choiceText = EpisodeTextImportUtils.removeAllCommandsFromString(step.speakerText);
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: choiceText,
              characterId: character?.id,
              answers: answers as AnswerStep[],
            };
            stepsToCreate.push(stepToCreate);
            continue;
          }
          case EpisodeTextEntityType.Answer: {
            console.error(`[ImportTextModal] Answer step can be implemented in Choice step: ${step.speakerText}`);
            break;
          }
          case EpisodeTextEntityType.Chat: {
            const stepCharacterName = speakerName.toLocaleLowerCase();
            const character = characters?.find((el) => el.alias.toLocaleLowerCase() === stepCharacterName);
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: speakerText,
              characterId: character?.id,
            };
            break;
          }

          default:
            stepToCreate = {
              ...stepToCreate,
              stepTypeId,
              text: speakerText,
            };
            break;
        }

        const gotoNodeCommand = step.commands.get(EpisodeTextImportCommandKey.Goto);

        if (gotoNodeCommand) {
          const toNodeTitle = gotoNodeCommand.values().next().value;
          const toNode = createdNodes.find((el) => el.title === toNodeTitle);
          if (!toNode) {
            console.error('[ImportTextModal] To node not found');
            continue;
          }
          nodeToUpdate = {
            ...nodeToUpdate,
            gotoBranchId: toNode.id,
          };
        } else if (isLastStep && !isLastNode) {
          const nextNode = createdNodes[nodeIndex + 1];
          nodeToUpdate = {
            ...nodeToUpdate,
            gotoBranchId: nextNode.id,
          };
        }

        if (!isEmpty(stepToCreate)) {
          stepsToCreate.push(stepToCreate);
        }
      }

      if (stepsToCreate.length > 0) {
        nodeToUpdate = {
          ...nodeToUpdate,
          steps: stepsToCreate as BranchStep[],
        };
      }

      /*
        Reposition nodes by tree
       */
      if (nodeToUpdate.title?.toLowerCase() !== 'intro') {
        const nodesToReposition = [...updatedNodes, nodeToUpdate] as Branch[];
        const tree = createNodeTree(nodesToReposition);
        const positions = EpisodeTextImportUtils.assignPositions(tree, nodesToReposition[0].id, { x: 0, y: lowestNodeY + 350 });
        if (nodeToUpdate.id) {
          const position = positions[nodeToUpdate.id];
          if (position) {
            nodeToUpdate = {
              ...nodeToUpdate,
              ...position,
            };
          } else {
            nodeToUpdate = {
              ...nodeToUpdate,
              y: firstUnlinkedNodePositionY,
            };
            firstUnlinkedNodePositionY += 350;
          }
        }
      }

      try {
        await apiService.updateEpisodeNode(episodeId, createdNode.id, nodeToUpdate);
        updatedNodes.push(nodeToUpdate as Branch);
      } catch (error) {
        showToast({ textMessage: `Error in updating node: ${createdNode.title}` });
        console.error(`[ImportTextModal] Error in updating node: ${createdNode.title}`, error);
        continue;
      }
    }
    return updatedNodes;
  }

  const handleImportButton = async () => {
    if (!episodeTextImport) {
      console.error('[ImportTextModal] No episode text import');
      return;
    }

    setToLoading();
    const episodeTextImportNodes = episodeTextImport.nodes;
    const createdNodes = await createEpisodeNodes(episodeTextImportNodes);

    if (createdNodes.length === 0) {
      showToast({ textMessage: 'No nodes created' });
      console.error('[ImportTextModal] No nodes created');
      setToError('No nodes created');
      return;
    }

    await updateEpisodeNodes(createdNodes, episodeTextImportNodes);

    setToSuccess();
    onSuccess(createdNodes[0].id ?? 0);
  };

  const handleValidateButton = () => {
    if (isEmpty(text)) {
      return;
    }

    const newEpisodeTextImport = EpisodeTextImportUtils.convertTextToEpisodeText(text);
    if (!newEpisodeTextImport) {
      console.error('[ImportTextModal] Error in parsing text');
      return;
    }
    console.log('episodeTextImport', { ...newEpisodeTextImport });
    setEpisodeTextImport(newEpisodeTextImport);

    const newTableData = newEpisodeTextImport.createTable(
      nodes.map((node) => node.title),
      characters?.map((character) => character.alias) ?? [],
      bookLocations?.map((location) => location.title) ?? [],
    );
    setTableData(newTableData);
  };

  const columns = useImportTextColumns();

  const isImportDisabled = tableData.length === 0
  || tableData.some((data) => data.errors?.length > 0);

  return (
    <>
      <Modal
        show
        size="xl"
        scrollable
        dialogClassName={css.modalDialog}
      >
        <Modal.Header>
          <Modal.Title>
            Import as Text
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tableData.length === 0 && (
            <Form.Group className="mb-3">
              <Form.Label>
                Please paste the text with nodes and steps
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={20}
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={`Example:
                
Intro\t\t
\tExample of NARRATOR step type\t
\tWhen character alias is empty - it will be NARRATOR step type\t
\t#GOTO: location_2\t
Location_2\t\t
Anna\tExample of DIALOGUE step type\t
player\tWhen character alias and text is present - it will be DIALOGUE step type\t
Serge\tExample 1 of TEXTING step type\t#CHAT
player\tExample 2 of TEXTING step type\t#CHAT
\t#-> choice_1\t
Choice_1\t\t
anna\tExample of CHOICE step type\t#CHOICE
\tExample of answer 1\t#GOTO: choice_1_a1
\tExample of answer 2\t#GOTO: choice_1_a2
\tExample of answer 3\t#GOTO: choice_1_a3
Choice_1_A1\t\t
anna\tYou choose answer 1, Will go to location_4\t
\t#-> location_4\t
Choice_1_A2\t\t
serge\tYou choose answer 2, Will go to location_4\t
\t#-> location_4\t
Choice_1_A3\t\t
\tYou choose answer 3, Will go to end\t
\t#-> end\t
Location_4\t\t#LOCATION: car1
\tExample of #LOCATION command\t
End\t\t
\tTo be continued\t`}
              />
            </Form.Group>
          )}
          {tableData.length > 0 && (
          <BootstrapTable
            hover
            condensed
            bootstrap4
            keyField="id"
            columns={columns}
            data={tableData}
            noDataIndication="Table is Empty"
          />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Col>
            {tableData.length ? `Total nodes: ${episodeTextImport?.nodes.length ?? 0}` : ''}
          </Col>
          <Col md="auto">
            <Button
              onClick={handleCancelButton}
              className="mr-2"
            >
              Close
            </Button>
            <Button
              variant="secondary"
              className="mr-2"
              onClick={() => {
                setText('');
                setTableData([]);
              }}
            >
              Clear
            </Button>
            {tableData.length === 0
              ? (<Button onClick={handleValidateButton}>              Validate            </Button>)
              : (<Button disabled={isImportDisabled} onClick={handleImportButton}>Import</Button>)}
          </Col>
        </Modal.Footer>
      </Modal>
      {requestStatus === RequestState.Loading && (
        <div className="text-center loadingSpinner" style={{ zIndex: 100000 }}>
          <Spinner variant="primary" animation="border" role="status" />
          <div className="overlay" />
        </div>
      )}
    </>
  );
}
