// TODO: should be moved to shared some time later
/**
 * Returns chunks of the original array
 */
export const arrayChunks = <T>(arr: T[], chunkSize: number): T[][] =>
    arr.reduce<T[][]>((chunkArr, item) => {
        const lastArr = chunkArr[chunkArr.length - 1];
        if (lastArr.length < chunkSize) {
            lastArr.push(item);
        } else {
            chunkArr.push([item]);
        }
        return chunkArr;
    }, [[]]);
