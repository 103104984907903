import { sortBy } from 'lodash';
import {Book, Branch, Branches, StepAnswer, StepSwitch, StepType, Story} from './bookEconomy';
import {MemoryCheck, MemoryGrant, MemoryGrantType} from './AnalyseStoryFlowsEconomyManager';

const unique = <T>(arr: T[]): T[] => [...new Set(arr)];

const storyToMermaid = (story: Story) => {
  let result: string[][] = BookEconomyStoryManager.getBranchesLinks(story);
  result.push(['[*]', 'intro']);

  const esc = (brName: string) => brName.replace(/-/g, '_');
  const add = (from: string, to: string) => {
    if (!result.find(item => item[0] === from && item[1] === to)) {
      result.push([from, to]);
    }
  };

  for (const [branchName, branchData] of Object.entries(story.contents.branches)) {
    for (const step of branchData.steps) {
      switch (step.type) {
        case StepType.Ending:
          add(esc(branchName), '[*]');
          break;
      }
    }
  }

  result.sort((a, b) => a[0].localeCompare(b[0]));

  const branchTypes = Object.fromEntries(
      Object.entries(story.contents.branches)
          .map(([name, branch]) => {
            let type = '';

            switch (branch.steps[0].type) {
                case StepType.Choice:
                    type = 'Choice';
                    break;
                case StepType.Check:
                    type = 'Check';
                    break;
            }

            return [name, type !== '' ? `${name}__${type}` : name];
          })
  );
  // console.log('choices:', Object.values(branchTypes).filter(type => type.includes('Choice')));
  // console.log('checks:', Object.values(branchTypes).filter(type => type.includes('Check')));

  result = result.map(([from, to]) => [
    from !== '[*]' ? branchTypes[from] : from,
    to !== '[*]' ? branchTypes[to] : to
  ]);

  return [
    '---',
    `title: "${story.title}"`,
    '---',
    'stateDiagram-v2',
    ...result.map(
        ([from, to]) => `    ${esc(from)} --> ${esc(to)}`
    ),
  ].join('\n');
};

export class BookEconomyStoryManager {
  private _book: Book;
  private _stories: Story[] = [];

  constructor(exportedBook: Book) {
    this._book = {
      ...exportedBook,
      stories: exportedBook.stories.map((story) => ({
        ...story,
        contents: {
          branches: Object.fromEntries(
              Object.entries(story.contents.branches)
                .map(([key, value]) => [key, {
                  ...value,
                  name: key,
                }])
          ),
        },
      })),
    };
    this._stories = sortBy(this._book.stories, (story) => story.chapter);

    this._optimizeBranches();
    this._cacheChecksAndGrants();
    // console.log(storyToMermaid(this._stories[21]));
    // console.log(storyToMermaid(this._stories[1]));
    // console.log(storyToMermaid(this._stories[0]));
  }

  private _retargetBranches(branches: Branch[], from: string, to: string) {
    for (const branch of branches) {
      const firstStep = branch.steps[0];
      if (firstStep.type === StepType.Choice) {
        // iterate answers
        const answers: StepAnswer[] = firstStep.answers ?? [];
        for (const answer of answers) {
          if (answer?.goto?.branch === from) {
            answer.goto.branch = to;
          }
        }
      } else if (firstStep.type === StepType.Check) {
        // iterate switches
        const switches: StepSwitch[] = firstStep.switch ?? [];
        for (const sw of switches) {
          if (sw?.goto?.branch === from) {
            sw.goto.branch = to;
          }
        }
      } else {
        const lastStep = branch.steps?.[branch.steps.length - 1];
        if (lastStep?.goto?.branch) {
          lastStep.goto.branch = to;
        }
      }
    }
  }

  private _optimizeBranches() {
    const isRegularBranch = (branch: Branch) => ![StepType.Choice, StepType.Check, StepType.Ending].includes(branch.steps[0].type);
    const isChoiceOrCheckBranch = (branch: Branch) => [StepType.Choice, StepType.Check].includes(branch.steps[0].type);

    for (const story of this._stories) {
      // console.log(`optimize story ${story.title}`);
      // remove diamond choices and choices leading to the same branch
      for (const [branchName, branchData] of Object.entries(story.contents.branches)) {
        if (!isChoiceOrCheckBranch(branchData)) {
          continue;
        }

        const answers: StepAnswer[] = branchData.steps[0]?.answers ?? [];
        const outputBranchNames = unique(
            answers
                .map(answer => answer.goto?.branch)
                .filter(name => name !== undefined)
        );
        if (outputBranchNames.length === 0) {
          continue;
        }
        const outputBranches = outputBranchNames.map(name => story.contents.branches[name]);
        const originalLinks: string[][] = BookEconomyStoryManager.getBranchesLinks(story);
        if (outputBranches.length === 1) {
          // remove useless choice - all answers lead to the same branch
          const outputBranch = outputBranches[0];
          const inputBanches = originalLinks
              .filter(([_from, to]) => to === branchName)
              .map(([from]) => story.contents.branches[from]);
          this._retargetBranches(inputBanches, branchName, outputBranch.name);
          delete story.contents.branches[branchName];
          // console.log(` -> ❌ REMOVING ${branchName} => ${outputBranch.name} (useless choice)`);
          continue;
        }

        // remove diamond choice
        if (!outputBranches.every(branch =>
            isRegularBranch(branch)
            && !branch.steps.some(step => step.type === StepType.Remember)
            // links from other branches do not exist
            && originalLinks
                .filter(([from, to]) => to === branch.name && from !== branchName)
                .map(([from]) => from)
                .length === 0
        )) {
          continue;
        }

        const nextLevelBranches = unique(
            outputBranches
                .map(branch =>
                    branch.steps?.[branch.steps.length - 1]?.goto?.branch
                )
                .filter(name => name !== undefined)
        );
        if (nextLevelBranches.length > 1) {
          continue;
        }
        const nextLevelBranchName = nextLevelBranches[0]!;

        const inputBanches = originalLinks
            .filter(([_from, to]) => to === branchName)
            .map(([from]) => story.contents.branches[from]);
        this._retargetBranches(inputBanches, branchName, nextLevelBranchName);

        const branchesToCollapse = [...outputBranches, branchData];

        for (const branch of branchesToCollapse) {
          delete story.contents.branches[branch.name];
        }
        // console.log(` -> ❌ REMOVING ${branchesToCollapse.map(b => b.name).join(',')} (diamond choice) in favor of ${nextLevelBranchName}`);
      }

      // remove useless steps
      let uselessStepsRemoved = 0;
      for (const branch of Object.values(story.contents.branches)) {
        const newSteps = branch.steps.filter(step =>
            [StepType.Check, StepType.Choice, StepType.Remember, StepType.Ending].includes(step.type)
            || step.goto !== undefined
        );
        uselessStepsRemoved += branch.steps.length - newSteps.length;
        branch.steps = newSteps;
      }
      // console.log(` -> ❌ REMOVING ${uselessStepsRemoved} useless steps`);

      // collapse regular branches
      for (const [branchName, branchData] of Object.entries(story.contents.branches)) {
        const originalLinks: string[][] = BookEconomyStoryManager.getBranchesLinks(story);
        const linksToBranch = originalLinks.filter(([_from, to]) => to === branchName);
        if (linksToBranch.length === 1 && isRegularBranch(branchData)) {
          const [fromBranchName] = linksToBranch[0];
          const fromBranch = story.contents.branches[fromBranchName];
          if (!isRegularBranch(fromBranch)) {
            continue;
          }
        } else {
          continue;
        }

        const [fromBranchName] = linksToBranch[0];
        const fromBranch = story.contents.branches[fromBranchName];
        // console.log(` -> COLLAPSING ${branchName} => ${fromBranchName}`);

        for (const step of fromBranch.steps) {
          delete step.goto;
        }

        fromBranch.steps.push(...branchData.steps);
        delete story.contents.branches[branchName];
      }

      // console.log('');
    }
  }

  private _cacheChecksAndGrants() {
    for (const story of this._stories) {
      story.checks = [];
      story.grants = [];
      for (const [branchName, branchData] of Object.entries(story.contents.branches)) {
        for (const step of branchData.steps) {
          switch (step.type) {
            case StepType.Remember: {
              const {action} = step;
              const memory = action?.variable;
              if (!action || !memory || !['increase', 'decrease'].includes(action.type)) {
                // logger.debug(`Invalid memory action: ${action} in branch: ${branch.id}`);
                continue;
              }

              const grant: MemoryGrant = {
                branch: branchName,
                step: step.index,
                memory,
                points: Number(action.value),
                type: action.type as MemoryGrantType,
              };

              story.grants.push(grant);
            }
              break;
            case StepType.Check: {
              const {check} = step;
              const memory = check?.variable;
              if (!check || !memory) {
                continue;
              }

              const check2: MemoryCheck = {
                branch: branchName,
                step: step.index,
                memory,
                operator: check.operator,
              };
              story.checks.push(check2);
            }
              break;
          }
        }
      }
    }
  }

  public static getBranchesLinks(story: Story) {
    const result: string[][] = [];
    const add = (from: string, to: string) => {
      if (!result.find(item => item[0] === from && item[1] === to)) {
        result.push([from, to]);
      }
    };

    for (const [branchName, branchData] of Object.entries(story.contents.branches)) {
      for (const step of branchData.steps) {
        switch (step.type) {
          case StepType.Choice:
            for (const answer of step.answers ?? []) {
              if (answer?.goto?.branch) {
                add(branchName, answer.goto.branch);
              }
            }
            break;
          case StepType.Check:
            for (const stepSwitch of step.switch ?? []) {
              if (stepSwitch?.goto?.branch) {
                add(branchName, stepSwitch.goto.branch);
              }
            }
            break;
          default:
            if (step?.goto?.branch) {
              add(branchName, step.goto.branch);
            }
            break;
        }
      }
    }

    return result;
  }

  public getBranchesByStoryId(storyId: number): Branches | undefined {
    return this._stories.find((story) => story.id === storyId)?.contents?.branches;
  }

  public getVariables() {
    return this._book.variables;
  }

  public get stories(): Story[] {
    return this._stories;
  }
}
