import {MemoryCheck, MemoryGrant} from './AnalyseStoryFlowsEconomyManager';

export enum StepType {
    Dialogue = 'Dialogue',
    Narrator = 'Narrator',
    Choice = 'Choice',
    Ending = 'Ending',
    Result = 'Result',
    PlayerSelect = 'PlayerSelect',
    Texting = 'Texting',
    Thinking = 'Thinking',
    Reaction = 'Reaction',
    Check = 'Check',
    Remember = 'Remember',
}

export type BookVariableValue = boolean | string | number;
export type AuxBookVariableValue = {
    type: 'variable[]';
    variable: string[];
}

export type GotoBranch = {
    branch: string;
}

export type StepAnswer = {
    type: string;
    text: string;
    goto: GotoBranch;
    requirement?: {
        check: StepCheck;
        visualTreatmentIfNotMet: 'Disable' | 'Hide';
    }
}

export type StepCheckOperator = 'equal' | 'notEqual' | 'greater' | 'atLeast' | 'less' | 'atMost' | 'min' | 'max';
export type StepCheck = {
    operator: StepCheckOperator;
    value: BookVariableValue | AuxBookVariableValue;
    variable: string;
}

export type StepSwitch = {
    value: BookVariableValue;
    goto: GotoBranch;
}

export type StepActionType = 'set' | 'increase' | 'decrease';
export type StepAction = {
    type: StepActionType;
    value: BookVariableValue;
    variable: string;
}

export type Step = {
    index: number;
    text: string;
    type: StepType;
    goto?: GotoBranch;
    check?: StepCheck;
    answers?: StepAnswer[];
    switch?: StepSwitch[];
    action?: StepAction;
}

export type Branch = {
    id: string;
    name: string;
    steps: Step[];
}

export type Branches = {
    [key: string]: Branch;
}

export type Story = {
    id: number;
    chapter: number;
    title: string;
    revision: number;
    uuid: string;
    contents: {
        branches: Branches;
    }
    grants?: MemoryGrant[];
    checks?: MemoryCheck[];
}

export type BookVariable = {
    defaultChangeDescription: string;
    defaultValue: BookVariableValue;
    displayName: string;
    icon: string | null;
    showIn: string[];
    type: 'number' | 'boolean' | 'string' | 'character';
}

export type Book = {
    id: number;
    title: string,
    uuid: string,
    stories: Story[],
    variables: Record<string, BookVariable>;
}

export type MemoryBank = Record<string, BookVariableValue>;
