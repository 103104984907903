import React, { ReactNode } from 'react';
import { MemoryShowIn } from './memoryBankTypes';

export const stringOrNumberValuePattern = '^([A-Za-z]|[0-9]|_|-| |[.])+$';

export function filterAndSortChildren(nodes: ReactNode[], filterString: string): ReactNode[] {
  try {
    const filteredChildren = React.Children.toArray(nodes).filter((node) => {
      if (React.isValidElement(node)) {
        const childNode = node.props.children;
        return childNode.props.children?.toLowerCase().includes(filterString.toLowerCase());
      }
      return false;
    });
    return filteredChildren.sort((a, b) => {
      if (React.isValidElement(a) && React.isValidElement(b)) {
        const nameA = a.props.children.props.children.toLowerCase();
        const nameB = b.props.children.props.children.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
      }
      return 0;
    });
  } catch (e) {
    console.error('Error while filtering and sorting children', e);
    return nodes;
  }
}
