import { isEmpty } from 'lodash-es';
import shortUUID from 'short-uuid';
import {
  EpisodeTextColumnKey,
  EpisodeTextEntityType,
  EpisodeTextImportCommands,
  EpisodeTextImportNodeValidationConfig,
} from '../types/types';
import { EpisodeTextImportStep } from './EpisodeTextImportStep';
import { EpisodeTextImportUtils } from './EpisodeTextImportUtils';

export class EpisodeTextImportNode {
  public id: string;
  public title: string;
  public text: string;
  public description: string;
  public steps: EpisodeTextImportStep[] = [];
  public lastStep: EpisodeTextImportStep | undefined = undefined;
  public errors: string[] = [];
  public commands: EpisodeTextImportCommands = new Map();

  constructor(data: string[]) {
    this.id = shortUUID.generate();
    this.title = data[EpisodeTextColumnKey.NodeOrSpeaker]?.trim() ?? '';
    this.text = data[EpisodeTextColumnKey.Text]?.trim() ?? '';
    this.description = data[EpisodeTextColumnKey.Description]?.trim() ?? '';
    this.commands = new Map();
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.NodeOrSpeaker, this.title, this.commands);
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.Text, this.text, this.commands);
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.Description, this.description, this.commands);
  }

  public addStep(step: EpisodeTextImportStep) {
    this.steps.push(step);
    this.lastStep = step;
  }

  public validate(config?: EpisodeTextImportNodeValidationConfig): string[] {
    const maxLengthName = config?.maxLengthName ?? 64;
    const maxLengthText = config?.maxLengthText ?? 200;
    const nodeNamesInEpisode = config?.nodeNamesInEpisode ?? [];
    const nodeNamesInImport = config?.nodeNamesInImport ?? [];
    const locationsNamesInEpisode = config?.locationsNamesInEpisode ?? [];

    const nodeNamePattern = /^[A-Za-z0-9_]+$/; // allowed characters:
    const title = EpisodeTextImportUtils.removeAllCommandsFromString(this.title);
    const text = EpisodeTextImportUtils.removeAllCommandsFromString(this.text);
    const description = EpisodeTextImportUtils.removeAllCommandsFromString(this.description);

    this.errors = [];

    this._validateCommands(locationsNamesInEpisode);

    if (title.length > Number(maxLengthName)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Node name exceeds the limit of ${maxLengthName} characters`);
    }

    if (description.length > Number(maxLengthText)) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] Node description exceeds the limit of ${maxLengthText} characters`);
    }

    if (!nodeNamePattern.test(title)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Check for spaces or invalid characters. Allowed characters: A-Z, a-z, 0-9, _`);
    }
    if (!isEmpty(text)) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] This cell should be empty for NODE row`);
    }

    const isIntro = title.toLocaleLowerCase() === 'intro';
    if (!isIntro && EpisodeTextImportUtils.isNameExists(title, nodeNamesInEpisode)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] This node name is already used in existing episode`);
    }

    const nodeTitleCount = nodeNamesInImport.filter((name) => name.toLocaleLowerCase() === title.toLocaleLowerCase());
    if (nodeTitleCount.length > 1) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] This node name is duplicated in the import text`);
    }

    /* Validate CHOICE */
    const isChoiceNode = this.steps[0]?.type === EpisodeTextEntityType.Choice;
    if (isChoiceNode) {
      const answersCount = this.steps.filter((step) => step.type === EpisodeTextEntityType.Answer).length;
      if (answersCount !== this.steps.length - 1) {
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] In CHOICE node, only ANSWER steps should be present`);
      }
      if (answersCount === 0) {
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] At least ONE answer step should be present`);
      }
      if (answersCount > 3) {
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Only THREE answers should be present`);
      }
    }

    return this.errors;
  }

  private _validateCommands(locationsNamesInEpisode: string[]) {
    const commands = EpisodeTextImportUtils.getCommands(this.commands);
    const hasLocationCommandInDescription = commands.locationCommand.has(EpisodeTextColumnKey.Description);
    const locationCommandValue = commands.locationCommand.get(EpisodeTextColumnKey.Description) as string;

    if (commands.chatCommand.size > 0) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #CHAT: command cannot be used in NODE row`);
    }
    if (commands.gotoCommand.size > 0) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: command cannot be used in NODE row`);
    }
    if (commands.choiceCommand.size > 0) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #CHOICE: command cannot be used in NODE row`);
    }
    // const locationNodeDescription = commands.locationCommand.get(EpisodeTextColumnKey.Description) as string;
    if (hasLocationCommandInDescription
      && !EpisodeTextImportUtils.isNameExists(locationCommandValue, locationsNamesInEpisode)) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #LOCATION: Location name is not exists in the episode. (${locationCommandValue})`);
    }
  }

  public getSyntaxText() {
    const name = `# NODE: ${this.title}`;
    const description = `# DESCRIPTION: ${this.description ?? ''}`;
    const steps = this.steps.map((step) => step.getSyntaxText()).join('\n');
    return `${name}\n${description}\n\n${steps}\n\n`;
  }
}
