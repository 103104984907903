import {
  EliminationAnswerResultType,
} from '../../../components/pages/StoryBranches/BranchEdit/Steps/AnswerEliminationType';

export type AnswerStepType = { title: string, value: string };
export type AnswerStepData = { key: string, value: string };
export type AnswerStepModifier = { answerModifierTypeId: number, value: string };

export enum AnswerTypeId {
    Erotic = 2,
    Romantic = 3,
    Free = 4,
    Paid = 6,
}

export type AnswerStep = {
    answerPrice: number;
    answerType: AnswerStepType;
    answerTypeId: number;
    data?: AnswerStepData[];
    gotoBranchId: number;
    id: number;
    modifiers: AnswerStepModifier[];
    requires?: [];
    tags?: string[];
    text: string;
    traits?: [];
    // FIXME: Optional because this field is not in CT type definition
    eliminationResultType?: EliminationAnswerResultType;
    // FIXME: Optional because this field is not in CT type definition
    eliminationResultText?: string;
}
