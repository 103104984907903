import { isEmpty } from 'lodash-es';
import { Branch } from '../../../../dorian-shared/types/branch/Branch';
import { StepTypes } from '../../../../dorian-shared/types/branch/BranchStep';
import {
  EpisodeTextColumnKey,
  EpisodeTextEntityType,
  EpisodeTextImportCommandKey,
  EpisodeTextImportCommands,
  EpisodeTextImportCommandTypes,
  EpisodeTextImportCommandValue,
} from '../types/types';
import { EpisodeTextImport } from './EpisodeTextImport';
import { EpisodeTextImportNode } from './EpisodeTextImportNode';
import { EpisodeTextImportStep } from './EpisodeTextImportStep';

export class EpisodeTextImportUtils {
  public static getRowType(importRow: string[], prevType?: EpisodeTextEntityType): EpisodeTextEntityType {
    const nodeOrSpeaker = importRow[EpisodeTextColumnKey.NodeOrSpeaker] ?? '';
    const text = importRow[EpisodeTextColumnKey.Text] ?? '';
    const description = importRow[EpisodeTextColumnKey.Description] ?? '';

    const commandsIn: EpisodeTextImportCommands = new Map();

    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.NodeOrSpeaker, nodeOrSpeaker, commandsIn);
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.Text, text, commandsIn);
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.Description, description, commandsIn);

    const commands = EpisodeTextImportUtils.getCommands(commandsIn);

    const isChoiceCommand = commands.choiceCommand.has(EpisodeTextColumnKey.Text) || commands.choiceCommand.has(EpisodeTextColumnKey.Description);
    if (isChoiceCommand) {
      return EpisodeTextEntityType.Choice;
    }

    const isChat = commands.chatCommand.get(EpisodeTextColumnKey.Description);
    if (isChat && !isEmpty(nodeOrSpeaker) && !isEmpty(text)) {
      return EpisodeTextEntityType.Chat;
    }

    const isGotoFromNode = commands.gotoCommand.get(EpisodeTextColumnKey.Text);
    if (isGotoFromNode && isEmpty(nodeOrSpeaker)) {
      return EpisodeTextEntityType.GotoFromNode;
    }

    const isNode = !isEmpty(nodeOrSpeaker) && isEmpty(text);
    if (isNode) {
      return EpisodeTextEntityType.Node;
    }

    if (prevType === EpisodeTextEntityType.Choice || prevType === EpisodeTextEntityType.Answer) {
      return EpisodeTextEntityType.Answer;
    }

    const isDialogue = !isEmpty(nodeOrSpeaker) && !isEmpty(text);
    if (isDialogue) {
      return EpisodeTextEntityType.Dialogue;
    }

    const isNarration = isEmpty(nodeOrSpeaker) && !isEmpty(text);
    if (isNarration) {
      return EpisodeTextEntityType.Narration;
    }

    return EpisodeTextEntityType.EmptyStep;
  }

  public static convertTextToEpisodeText(text: string): EpisodeTextImport | undefined {
    const episodeTextImport = new EpisodeTextImport();
    const rows = text.split('\n');
    const importRows = rows.map((row) => row.split('\t'));
    const firstIndex = importRows.findIndex((row) => EpisodeTextImportUtils.getRowType(row) === EpisodeTextEntityType.Node);
    if (firstIndex === -1) {
      return undefined;
    }

    let rowType: EpisodeTextEntityType = EpisodeTextEntityType.Skip;

    for (let rowIndex = firstIndex; rowIndex < importRows.length; rowIndex++) {
      const importRow = importRows[rowIndex] ?? [];

      rowType = EpisodeTextImportUtils.getRowType(importRow, rowType);

      switch (rowType) {
        case EpisodeTextEntityType.Node: {
          const node = new EpisodeTextImportNode(importRow);
          episodeTextImport.addNode(node);
          break;
        }
        case EpisodeTextEntityType.Dialogue:
        case EpisodeTextEntityType.Narration:
        case EpisodeTextEntityType.GotoFromNode:
        case EpisodeTextEntityType.Chat:
        case EpisodeTextEntityType.Choice:
        case EpisodeTextEntityType.Answer: {
          const step = new EpisodeTextImportStep(importRow, rowType);
          episodeTextImport.addStep(step);
          break;
        }
        case EpisodeTextEntityType.EmptyStep:
        case EpisodeTextEntityType.Skip:
          console.log('[EpisodeTextImportUtils] Skip row', importRow);
          break;
        default:
          break;
      }
    }
    return episodeTextImport;
  }

  public static parseCommands(
    columnKey: EpisodeTextColumnKey,
    text: string,
    commands: EpisodeTextImportCommands = new Map(),
  ):
    EpisodeTextImportCommands {
    Object.entries(EpisodeTextImportCommandTypes).forEach(([key, value]) => {
      for (let valueIndex = 0; valueIndex < value.length; valueIndex++) {
        const command = value[valueIndex];

        const match = text.match(command.pattern);
        if (match) {
          const commandValue: EpisodeTextImportCommandValue = commands.get(key as EpisodeTextImportCommandKey) ?? new Map();
          commandValue.set(columnKey, match[command.matchValueGroup]);
          commands.set(key as EpisodeTextImportCommandKey, commandValue);
          break;
        }
      }
    });
    return commands;
  }

  public static getLowestYInNodes(nodes: Branch[]) {
    return nodes.reduce((acc, node) => {
      if (node.y > acc) {
        return node.y;
      }
      return acc;
    }, 0);
  }

  public static getStepTypeIdByEntityType(entityType: EpisodeTextEntityType | undefined): number | undefined {
    switch (entityType) {
      case EpisodeTextEntityType.Dialogue:
        return StepTypes.Dialogue;
      case EpisodeTextEntityType.Narration:
        return StepTypes.Narrator;
      case EpisodeTextEntityType.Choice:
        return StepTypes.Choice;
      case EpisodeTextEntityType.Chat:
        return StepTypes.Texting;
      default:
        return undefined;
    }
  }

  public static isNameExists(name: string, existingNames: string[]): boolean {
    if (isEmpty(name)) return true;
    return existingNames.some((el) => el.toLocaleLowerCase() === name.toLocaleLowerCase());
  }

  public static removeAllCommandsFromString(text: string) {
    let newText = text;
    Object.values(EpisodeTextImportCommandTypes).forEach((commandType) => {
      commandType.forEach((command) => {
        newText = newText.replace(command.pattern, '');
      });
    });
    return newText.trim();
  }

  private static _calculateDepths = (tree: {[key: number]:number[]}, startNode: number): {[key: number]: number} => {
    const depths: { [key: number]: number} = {};

    function dfs(node: number, depth: number) {
      if (depths[node] === undefined) {
        depths[node] = depth;
        if (tree[node]) {
          tree[node].forEach((child) => dfs(child, depth + 1));
        }
      }
    }

    dfs(startNode, 0);
    return depths;
  };

  public static assignPositions = (
    tree: {[key: string]:number[]},
    startNode: number,
    initialPosition: {x: number, y: number} = { x: 0, y: 0 },
  ) => {
    const offsetX = 300;
    const offsetY = 350;
    const levelCounts: {[key: string]: number} = {};

    const depths: { [key: string]: number} = this._calculateDepths(tree, startNode);

    return Object.keys(depths)
      .reduce((acc, node) => {
        const depth = depths[node];
        if (!levelCounts[depth]) {
          levelCounts[depth] = 0;
        }
        const x = initialPosition.x + (levelCounts[depth] * offsetX);
        const y = initialPosition.y + (depth * offsetY);
        acc[node] = { x, y };
        levelCounts[depth] += 1;
        return acc;
      }, {} as {[key: string]: {x: number, y: number}});
  };

  public static getCommands(commands: EpisodeTextImportCommands) {
    const gotoCommand = commands.get(EpisodeTextImportCommandKey.Goto) ?? new Map();
    const choiceCommand = commands.get(EpisodeTextImportCommandKey.Choice) ?? new Map();
    const locationCommand = commands.get(EpisodeTextImportCommandKey.Location) ?? new Map();
    const chatCommand = commands.get(EpisodeTextImportCommandKey.Chat) ?? new Map();
    return {
      gotoCommand,
      choiceCommand,
      locationCommand,
      chatCommand,
    };
  }
}
