import React from 'react';
import {
  Button, Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { Branch } from '../../../dorian-shared/types/branch/Branch';
import { api } from '../../api';
import { showToast } from '../../ui/utils';
import cs from './DeleteBranch.module.scss';

interface DeleteBranchProps {
  show: boolean
  storyId: number
  branches: Branch[]
  onDelete: () => void
  onCancel?: () => void
}

export function DeleteBranch(props: DeleteBranchProps) {
  const { onCancel, show, branches } = props;

  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  // TODO: Ask about deleting by package
  const deleteBranches = async () => {
    const { onDelete, storyId } = props;

    setToLoading();
    try {
      for (let i = 0; i < branches.length; i++) {
        const branch = branches[i];
        // eslint-disable-next-line no-await-in-loop
        await api.delete(`/v1/stories/${storyId}/branches/${branch.id}`);
      }
      setToSuccess();
    } catch (error) {
      setToError();
      if (error instanceof Error) {
        showToast({ textMessage: error.message });
      } else {
        showToast({ textMessage: 'Error deleting node(s)' });
      }
    } finally {
      onDelete();
    }
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className={cs.modalTitle}>
          Deleting Nodes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center" style={{ maxHeight: '50vh' }}>
          Do you really want to delete the Node(s) ?
        </p>
        {branches.map((branch) => (
          <Row key={branch.id}>
            <Col className={cs.branchTitle}>
              {branch.title}
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => deleteBranches()}
          disabled={isLoading}
        >
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
