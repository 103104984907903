import { isEmpty } from 'lodash-es';
import shortUUID from 'short-uuid';
import {
  EpisodeTextColumnKey,
  EpisodeTextEntityType,
  EpisodeTextImportCommands,
  EpisodeTextImportStepValidationConfig,
} from '../types/types';
import { EpisodeTextImportUtils } from './EpisodeTextImportUtils';

export class EpisodeTextImportStep {
  public id: string;
  public type: EpisodeTextEntityType | undefined = undefined;
  public speakerName: string;
  public speakerText: string;
  public description: string;
  public errors: string[] = [];
  public commands: EpisodeTextImportCommands = new Map();

  constructor(data: string[], stepType: EpisodeTextEntityType) {
    this.id = shortUUID.generate();
    this.speakerName = data[EpisodeTextColumnKey.NodeOrSpeaker]?.trim() ?? '';
    this.speakerText = data[EpisodeTextColumnKey.Text]?.trim() ?? '';
    this.description = data[EpisodeTextColumnKey.Description]?.trim() ?? '';
    this.type = stepType;
    this.commands = new Map();
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.NodeOrSpeaker, this.speakerName, this.commands);
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.Text, this.speakerText, this.commands);
    EpisodeTextImportUtils.parseCommands(EpisodeTextColumnKey.Description, this.description, this.commands);
  }

  public validate(config?: EpisodeTextImportStepValidationConfig): string[] {
    const maxLengthName = config?.maxLengthName ?? 100;
    const maxLengthText = config?.maxLengthText ?? 120;
    const existingNodeNames = config?.nodeNamesInImport ?? [];
    const characterNamesInEpisode = config?.characterNamesInEpisode ?? [];

    const speakerNamePattern = /^[0-9A-z\-_]+$/;
    const speakerName = EpisodeTextImportUtils.removeAllCommandsFromString(this.speakerName);
    const speakerText = EpisodeTextImportUtils.removeAllCommandsFromString(this.speakerText);

    this.errors = [];

    this._validateGotoCommand(existingNodeNames);
    this._validateLocationCommand();
    this._validateChoiceCommand();
    this._validateChatCommand();

    /* Validate OTHERS */
    if (this.type === EpisodeTextEntityType.EmptyStep) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Row should not be empty`);
    }

    if (speakerName.length > maxLengthName) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Character alias exceeds the limit of ${maxLengthName} characters`);
    }

    if (!isEmpty(speakerName) && !speakerNamePattern.test(speakerName)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Invalid character alias. Allowed characters: A-Z, a-z, 0-9, _, -`);
    }

    if (speakerText.length > maxLengthText) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] Speaker text exceeds the limit of ${maxLengthText} characters`);
    }

    if ((this.type === EpisodeTextEntityType.Dialogue || this.type === EpisodeTextEntityType.Choice)
      && !EpisodeTextImportUtils.isNameExists(this.speakerName, characterNamesInEpisode)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Character alias does not exist in the episode`);
    }

    if (this.type === EpisodeTextEntityType.Choice) {
      if (isEmpty(speakerName)) {
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Choice step should have a character alias`);
      }
      if (speakerText.length > 40) {
        this.errors.push(`[${EpisodeTextColumnKey.Text}] Choice text exceeds the limit of 40 characters`);
      }
    }

    if (this.type === EpisodeTextEntityType.Answer) {
      if (!isEmpty(speakerName)) {
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Answer step should not have a character alias`);
      }
      if (speakerText.length > 40) {
        this.errors.push(`[${EpisodeTextColumnKey.Text}] Answer text exceeds the limit of 40 characters`);
      }
    }

    return this.errors;
  }

  private _validateGotoCommand(existingNodeNames: string[]) {
    const commands = EpisodeTextImportUtils.getCommands(this.commands);
    const speakerName = EpisodeTextImportUtils.removeAllCommandsFromString(this.speakerName);

    const hasGotoCommandInSpeaker = commands.gotoCommand.has(EpisodeTextColumnKey.NodeOrSpeaker);
    const hasGotoCommandInText = commands.gotoCommand.has(EpisodeTextColumnKey.Text);
    const hasGotoCommandInDescription = commands.gotoCommand.has(EpisodeTextColumnKey.Description);

    const gotoInTextValue = commands.gotoCommand.get(EpisodeTextColumnKey.Text);
    const gotoInDescriptionValue = commands.gotoCommand.get(EpisodeTextColumnKey.Description);
    if (
      hasGotoCommandInDescription
      && this.type !== EpisodeTextEntityType.Answer
    ) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: command in description should be in ANSWER step type`);
    }

    if (hasGotoCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #GOTO: cannot be used in this cell`);
    }

    if (hasGotoCommandInText
      && !isEmpty(speakerName)) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] #GOTO: Character alias cannot be used with this command`);
    }

    if (hasGotoCommandInText
      && existingNodeNames.length > 0
      && !EpisodeTextImportUtils.isNameExists(String(gotoInTextValue), existingNodeNames)) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] #GOTO: name does not exist in importing nodes`);
    }

    if (hasGotoCommandInDescription
      && existingNodeNames.length > 0
      && !EpisodeTextImportUtils.isNameExists(String(gotoInDescriptionValue), existingNodeNames)) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: Node name does not exist in importing nodes`);
    }

    if (hasGotoCommandInDescription
      && isEmpty(gotoInDescriptionValue)) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: Node name should not be empty`);
    }

    if (commands.gotoCommand.size > 0
      && this.type === EpisodeTextEntityType.Choice) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] #GOTO: command should not be in CHOICE step type`);
    }
    if (!hasGotoCommandInDescription
      && this.type === EpisodeTextEntityType.Answer) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: command missing. It should be in ANSWER step type, or make sure you wrote the command correctly`);
    }
  }

  private _validateLocationCommand() {
    const commands = EpisodeTextImportUtils.getCommands(this.commands);

    const hasLocationCommandInSpeaker = commands.locationCommand.has(EpisodeTextColumnKey.NodeOrSpeaker);
    const hasLocationCommandInDescription = commands.locationCommand.has(EpisodeTextColumnKey.Description);

    if (hasLocationCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #LOCATION: command cannot be used in this step`);
    }

    if (hasLocationCommandInDescription) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #LOCATION: command cannot be used in this step`);
    }
  }

  private _validateChoiceCommand() {
    const commands = EpisodeTextImportUtils.getCommands(this.commands);

    const hasChoiceCommandInSpeaker = commands.choiceCommand.has(EpisodeTextColumnKey.NodeOrSpeaker);
    const hasChoiceCommandInDescription = commands.choiceCommand.has(EpisodeTextColumnKey.Description);

    if (hasChoiceCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #CHOICE: command cannot be used in this cell`);
    }

    if (hasChoiceCommandInDescription
      && this.type !== EpisodeTextEntityType.Choice) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #CHOICE: command cannot be used in this row type`);
    }
  }

  private _validateChatCommand() {
    const commands = EpisodeTextImportUtils.getCommands(this.commands);

    const hasChatCommandInSpeaker = commands.chatCommand.has(EpisodeTextColumnKey.NodeOrSpeaker);
    const hasChatCommandInText = commands.chatCommand.has(EpisodeTextColumnKey.Text);
    const hasChatCommandInDescription = commands.chatCommand.has(EpisodeTextColumnKey.Description);

    if (hasChatCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #CHAT: command should be in the DESCRIPTION column`);
    }
    if (hasChatCommandInText) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] #CHAT: command should be in the DESCRIPTION column`);
    }

    if (hasChatCommandInDescription
      && this.type !== EpisodeTextEntityType.Chat) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #CHAT: command cannot be used in this step`);
    }
  }

  public getSyntaxText() {
    const name = `(${this.speakerName}):`;
    const text = `${this.speakerText ?? ''}`;
    return `${name} ${text}`;
  }
}
