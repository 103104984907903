import {isEmpty} from 'lodash';

import {Branch, MemoryBank, Story} from './bookEconomy';

export enum MemoryGrantType {
  Increase = 'increase',
  Decrease = 'decrease',
  Set = 'set',
}

export type BranchList = Branch[];

export type MemoryGrant = {
  branch: string;
  step: number;
  memory: string;
  points: number;
  type: MemoryGrantType; // increase, decrease, set
}

export type MemoryCheck = {
  branch: string;
  step: number;
  memory: string;
  operator: string;
}

// flow-memory structure
export type MemoryEconomy = {
  memory: string;
  totalPoints: number;
  grants: MemoryGrant[];
  checks: MemoryCheck[];
}

const grantToPoints = (grant: MemoryGrant): number => (
    grant.type === MemoryGrantType.Decrease ? -grant.points : grant.points
);

const reduceGrantsToPoints = (grants: MemoryGrant[]): number => (
    grants.map(grantToPoints).reduce((acc, points) => acc + points, 0)
);

// resulting structure, after all flows are processed
export type MemoryEconomyStats = {
  // MAX FREE POINTS
  freeModeMaxPoints: number;
  // MAX PREMIUM POINTS
  paidModeMaxPoints: number;
  // MIN POINTS
  minPoints: number;

  // MIN - DECREASE GRANTS
  minDecreaseGrants: number;
  // MIN - DECREASE POINTS
  minDecreasePoints: number;

  // MAX FREE - TOTAL GRANTS
  freeModeMaxGrants: number;
  // MAX PREMIUM - TOTAL GRANTS
  paidModeMaxGrants: number;
  // MIN - TOTAL GRANTS #
  minTotalGrants: number;

  // MAX PREMIUM - FREE GRANTS
  paidModeMaxFreeGrants: number;
  // MIN - FREE GRANTS #
  freeModeMinGrants: number;

  // MAX PREMIUM - PREMIUM GRANTS
  paidModeMaxPaidGrants: number;
  // MAX PREMIUM - FREE NESTED GRANTS
  paidModeMaxFreeNestedGrants: number;

  // MAX FREE ROUTE - CHECKS
  maxFreeModeMaxChecks: number;
  // MAX PREMIUM ROUTE - CHECKS
  paidModeMaxChecks: number;

  // MIN CHECK POINT AMOUNT
  minChecks: number;
  // MAX CHECK POINT AMOUNT
  maxChecks: number;
}

export type MemoriesEconomyStats = Record<string, MemoryEconomyStats>;

export type MemoryCriticalFlows = {
  [index in keyof MemoryEconomyStats]?: StoryFlow[];
}
export type MemoryStorySummary = Partial<MemoryEconomyStats>;
export type MemoriesStorySummary = Record<string, MemoryStorySummary>;

export type MemoriesCriticalFlows = Record<string, MemoryCriticalFlows>;

export type TotalMemoryEconomy = {
  stats: MemoriesEconomyStats;
  flows: MemoriesCriticalFlows;
}

export type MemoriesEconomy = Record<string, MemoryEconomy>;

export type StoryFlow = {
  memories: MemoriesEconomy;
  flows: BranchList;
  paid: boolean;
  memoryBank: MemoryBank;
}
export type StoryFlowDraft = Omit<StoryFlow, 'flows' | 'memories'> & {
  branches: string[];
  isFinal: boolean;
  isDead: boolean;
}

const createDefaultMemoryEconomyStats = (): MemoryEconomyStats => ({
  freeModeMaxPoints: 0,
  paidModeMaxPoints: 0,
  minPoints: 1000000,

  minDecreaseGrants: 1000000,
  minDecreasePoints: 1000000,

  freeModeMaxGrants: 0,
  paidModeMaxGrants: 0,
  minTotalGrants: 1000000,

  paidModeMaxFreeGrants: 0,
  freeModeMinGrants: 1000000,

  paidModeMaxPaidGrants: 0,
  paidModeMaxFreeNestedGrants: 0,

  maxFreeModeMaxChecks: 0,
  paidModeMaxChecks: 0,

  minChecks: 1000000,
  maxChecks: 0,
});

export const getMemoriesByStoryFlow = (story: Story, storyFlows: BranchList, affectionPointsMemories: string[]): MemoriesEconomy => {
  const memories: MemoriesEconomy = {};
  const branches = storyFlows.map(branch => branch.name);

  for (const memory of affectionPointsMemories) {
    const grants: MemoryGrant[] = story.grants?.filter(grant => grant.memory === memory && branches.includes(grant.branch)) ?? [];
    const checks: MemoryCheck[] = story.checks?.filter(check => check.memory === memory && branches.includes(check.branch)) ?? [];

    if (grants.length > 0 || checks.length > 0) {
      memories[memory] = {
        memory,
        grants,
        checks,
        totalPoints: 0,
      }
    }
  }

  return memories;
};

export class AnalyseStoryFlowsEconomyManager {
  private _storyFlows: StoryFlow[] = [];

  public get storyFlows(): StoryFlow[] {
    return this._storyFlows;
  }

  public set storyFlows(value: StoryFlow[]) {
    this._storyFlows = value;
  }

  public isStoryFlowDuplicate(storyFlows: BranchList): boolean {
    for (let i = 0; i < this._storyFlows.length; i++) {
      const currentStoryFlows = this._storyFlows[i];
      if (currentStoryFlows.flows.length === storyFlows.length) {
        for (let j = 0; j < currentStoryFlows.flows.length; j++) {
          const currentStoryFlow = currentStoryFlows.flows[j];
          const storyFlow = storyFlows[j];
          if (currentStoryFlow.id !== storyFlow.id) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  public addStoryFlow(
      story: Story,
      storyFlows: BranchList,
      allowEmptyMemories = true,
      isPaid: boolean,
      affectionPointsMemories: string[],
      memoryBank: MemoryBank
  ): void {
    if (this.isStoryFlowDuplicate(storyFlows)) {
      // logger.log('Duplicate story flow...');
      return;
    }

    const storyFlowsEconomy: StoryFlow = {
      memories: getMemoriesByStoryFlow(story, storyFlows, affectionPointsMemories),
      flows: storyFlows,
      paid: isPaid,
      memoryBank,
    };

    if (!allowEmptyMemories && isEmpty(storyFlowsEconomy.memories)) {
      return;
    }

    this._storyFlows.push(storyFlowsEconomy);
  }

  public calculatePaidFlows() {
    return this._storyFlows.filter(flow => flow.paid);
  }

  public calculateFreeFlows() {
    return this._storyFlows.filter(flow => !flow.paid);
  }

  public calculatePaidBranchNames() {
    const paidFlows = this.calculatePaidFlows();
    const freeFlows = this.calculateFreeFlows();

    // setting up paid flows
    const paidBranchesNames = new Set<string>();
    const allPaidBranchNames = new Set(
        paidFlows
            .map(flow => flow.flows.map(branch => branch.name))
            .flat()
    );
    const allFreeBranchNames = new Set(
        freeFlows
            .map(flow => flow.flows.map(branch => branch.name))
            .flat()
    );
    for (const branch of allPaidBranchNames) {
      if (!allFreeBranchNames.has(branch)) {
        paidBranchesNames.add(branch);
      }
    }

    return paidBranchesNames;
  }

  public calculateEconomyStats(): MemoriesEconomyStats {
    const result: MemoriesEconomyStats = {};
    let memoryNames: string[] = [];

    // const paidFlows = this._storyFlows.filter(flow => flow.paid);
    // const freeFlows = this._storyFlows.filter(flow => !flow.paid);
    // console.log('paidFlows', paidFlows.length);
    // console.log('freeFlows', freeFlows.length);

    for (const {memories} of this._storyFlows) {
      memoryNames.push(...Object.keys(memories));
    }
    memoryNames = [...new Set(memoryNames)];
    memoryNames.sort();

    for (const memory of memoryNames) {
      result[memory] = createDefaultMemoryEconomyStats();
    }

    const paidBranchesNames = this.calculatePaidBranchNames();
    // console.log('paidBranchesNames', [...paidBranchesNames].join('\n'));

    // setting up paid branches
    const paidFlowsGrants: MemoryGrant[] = [];
    const paidFlows = this.calculatePaidFlows();
    for (const flow of paidFlows) {
      for (const memory of memoryNames) {
        const memoryEconomy = flow.memories[memory];
        if (!memoryEconomy) {
          continue;
        }

        for (const grant of memoryEconomy.grants) {
          if (!paidFlowsGrants.find(g => g.branch === grant.branch && g.step === grant.step)) {
            paidFlowsGrants.push(grant);
          }
        }
      }
    }

    // const paidModeFreeGrantBranches: {branch: string, step: number, grant: MemoryGrant}[] = [];

    for (const flow of this._storyFlows) {
      for (const memory of memoryNames) {
        const memoryEconomy = flow.memories[memory];
        if (!memoryEconomy) {
          continue;
        }

        const resultMemory = result[memory];
        const memoryGrants = memoryEconomy.grants;
        const totalGrants = memoryGrants.length;
        const totalDecreaseGrants = memoryGrants.filter(grant => grant.type === MemoryGrantType.Decrease);
        const freeGrants = memoryGrants.filter(grant => !paidBranchesNames.has(grant.branch));
        const paidGrants = memoryGrants.filter(grant => paidBranchesNames.has(grant.branch));
        // final points in this particular flow
        const freePoints = reduceGrantsToPoints(freeGrants);
        const paidPoints = reduceGrantsToPoints(paidGrants);
        const totalPoints = paidPoints + freePoints;
        memoryEconomy.totalPoints = totalPoints;

        if (flow.paid) {
          // paid mode
          resultMemory.paidModeMaxGrants = Math.max(resultMemory.paidModeMaxGrants, totalGrants);
          resultMemory.paidModeMaxPoints = Math.max(resultMemory.paidModeMaxPoints, totalPoints);
          resultMemory.paidModeMaxFreeGrants = Math.max(resultMemory.paidModeMaxFreeGrants, freeGrants.length);
          resultMemory.paidModeMaxPaidGrants = Math.max(resultMemory.paidModeMaxPaidGrants, paidGrants.length);
          const freeNestedGrants = freeGrants.filter(grant => !paidFlowsGrants.find(
              g => g.branch === grant.branch && g.step === grant.step
          ));
          resultMemory.paidModeMaxFreeNestedGrants = Math.max(resultMemory.paidModeMaxFreeNestedGrants, freeNestedGrants.length);
        } else {
          // free mode
          resultMemory.freeModeMaxGrants = Math.max(resultMemory.freeModeMaxGrants, totalGrants);
          resultMemory.freeModeMaxPoints = Math.max(resultMemory.freeModeMaxPoints, totalPoints);
          resultMemory.freeModeMinGrants = Math.min(resultMemory.freeModeMinGrants, totalGrants);
        }
        resultMemory.minPoints = Math.min(resultMemory.minPoints, totalPoints);
        resultMemory.minDecreaseGrants = Math.min(resultMemory.minDecreaseGrants, totalDecreaseGrants.length);
        const decreasePoints = reduceGrantsToPoints(totalDecreaseGrants);
        resultMemory.minDecreasePoints = Math.min(resultMemory.minDecreasePoints, decreasePoints);
        resultMemory.minTotalGrants = Math.min(resultMemory.minTotalGrants, totalGrants);
        resultMemory.minChecks = Math.min(resultMemory.minChecks, memoryEconomy.checks.length);
        resultMemory.maxChecks = Math.max(resultMemory.maxChecks, memoryEconomy.checks.length);
      }
    }

    // console.log('paid mode free grants:', paidModeFreeGrantBranches);

    for (const [memory, resultMemory] of Object.entries(result)) {
      const {freeModeMaxPoints, paidModeMaxPoints} = resultMemory;
      for (const flow of this._storyFlows) {
        const m = flow.memories[memory];
        // find max free routes
        if (freeModeMaxPoints === m?.totalPoints) {
          resultMemory.maxFreeModeMaxChecks = Math.max(resultMemory.maxFreeModeMaxChecks, m.checks.length);
        }
        // find max paid routes
        if (paidModeMaxPoints === m?.totalPoints) {
          resultMemory.paidModeMaxChecks = Math.max(resultMemory.paidModeMaxChecks, m.checks.length);
        }
      }
    }

    return result;
  }

  public getCriticalFlows(memoriesStats: MemoriesEconomyStats): MemoriesCriticalFlows {
    const result: MemoriesCriticalFlows = {};
    const paidBranchesNames = this.calculatePaidBranchNames();

    for (const [memory, memoryStats] of Object.entries(memoriesStats)) {
      result[memory] = {
        paidModeMaxFreeGrants: this._storyFlows.filter(flow => {
          if (!flow.memories[memory]) {
            return false;
          }

          const memoryGrants = flow.memories[memory].grants;
          const freeGrants = memoryGrants.filter(grant => !paidBranchesNames.has(grant.branch));

          return memoryStats.paidModeMaxFreeGrants === freeGrants.length;
        })
            .slice(0, 1),
      };
    }

    return result;
  }
}
