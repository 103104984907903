import React, { useMemo } from 'react';
import { BadgeWithTooltip } from '../../../ui/AvatarTool/BadgeWithTooltip/BadgeWithTooltip';
import { EpisodeTextColumnKey, EpisodeTextEntityType, EpisodeTextImportCommandKey } from '../types/types';

const nodeBackgroundColor = '#dddddd';
const errorBackgroundColor = '#ffcccc';

const commandColor = '#02780f';
const choiceColor = '#001f98';
const answerColor = '#004a78';

function ErrorBadge(props: { error: string }) {
  const { error } = props;
  return (
    <div>
      {error && (
      <div className="position-absolute" style={{ top: '-0.5rem', right: '-0.5rem' }}>
        <BadgeWithTooltip
          id="locked-memory-badge"
          title="Validation error"
          content={error}
          hideDelay={2000}
        />
      </div>
      )}
    </div>
  );
}

function getStyle(columnKey?: EpisodeTextColumnKey) {
  return (cell: string, row: any) => {
    const error = row.errors?.find((rowError: string) => rowError.startsWith(`[${columnKey}`));
    if (error) {
      return { backgroundColor: errorBackgroundColor };
    }

    switch (row.type) {
      case EpisodeTextEntityType.Node:
        switch (columnKey) {
          case EpisodeTextColumnKey.Description:
            if (row.commands?.get(EpisodeTextImportCommandKey.Location)) {
              return { backgroundColor: nodeBackgroundColor, color: commandColor, fontWeight: 'bold' };
            }
            break;
          default:
            break;
        }
        return { backgroundColor: nodeBackgroundColor, fontWeight: 'bold' };
      case EpisodeTextEntityType.GotoFromNode: {
        switch (columnKey) {
          case EpisodeTextColumnKey.Text:
            return { color: commandColor, fontWeight: 'bold' };
          default:
            break;
        }
        break;
      }
      case EpisodeTextEntityType.Choice:
        switch (columnKey) {
          case EpisodeTextColumnKey.Text:
            return { color: choiceColor, fontWeight: 'bold' };
          case EpisodeTextColumnKey.Description:
            return { color: commandColor, fontWeight: 'bold' };
          default:
            break;
        }
        break;
      case EpisodeTextEntityType.Answer: {
        switch (columnKey) {
          case EpisodeTextColumnKey.Text:
            return { color: answerColor, fontWeight: 'bold', fontStyle: 'italic' };
          case EpisodeTextColumnKey.Description:
            if (row.commands?.get(EpisodeTextImportCommandKey.Goto)) {
              return { color: commandColor, fontWeight: 'bold' };
            }
            break;
          default:
            break;
        }
        break;
      }
      case EpisodeTextEntityType.Chat: {
        switch (columnKey) {
          case EpisodeTextColumnKey.Description:
            if (row.commands?.get(EpisodeTextImportCommandKey.Chat)) {
              return { color: commandColor, fontWeight: 'bold' };
            }
            break;
          default:
            break;
        }
        break;
      }

      default:
        switch (columnKey) {
          case EpisodeTextColumnKey.Description:
            if (row.commands?.get(EpisodeTextImportCommandKey.Location)) {
              return { color: commandColor, fontWeight: 'bold' };
            }
            break;
          default:
            break;
        }
        break;
    }
    return {};
  };
}

function getFormatter(columnKey: EpisodeTextColumnKey) {
  return function (cell: string, row: any) {
    const error = row.errors?.find((rowError: string) => rowError.startsWith(`[${columnKey}`));
    return (
      <div className="d-flex position-relative">
        <ErrorBadge error={error} />
        {cell}
      </div>
    );
  };
}

export const useImportTextColumns = () => useMemo(() => [
  {
    dataField: 'type',
    text: 'Type',
    headerStyle: { width: '8%' },
    style: getStyle(),
  },
  {
    dataField: 'nodeOrSpeaker',
    text: 'Node/Speaker',
    headerStyle: { width: '12%' },
    style: getStyle(EpisodeTextColumnKey.NodeOrSpeaker),
    formatter: getFormatter(EpisodeTextColumnKey.NodeOrSpeaker),
  },
  {
    dataField: 'text',
    text: 'Text',
    style: getStyle(EpisodeTextColumnKey.Text),
    formatter: getFormatter(EpisodeTextColumnKey.Text),
  },
  {
    dataField: 'description',
    text: 'Description',
    headerStyle: { width: '20%' },
    style: getStyle(EpisodeTextColumnKey.Description),
    formatter: getFormatter(EpisodeTextColumnKey.Description),
  },
], []);
