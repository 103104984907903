export enum EpisodeTextColumnKey {
  NodeOrSpeaker = 0,
  Text = 1,
  Description = 2,
}

export enum EpisodeTextEntityType {
  Node = 'node',
  EmptyStep = 'emptyStep',
  Dialogue = 'dialogue',
  Narration = 'narration',
  Choice = 'choice',
  Answer = 'answer',
  Skip = 'skip',
  GotoFromNode = 'goto',
  Chat = 'chat',
}

export type EpisodeTextImportNodeValidationConfig = {
  maxLengthName?: number;
  maxLengthText?: number;
  nodeNamesInEpisode?: string[];
  nodeNamesInImport?: string[];
  locationsNamesInEpisode?: string[];
}

export type EpisodeTextImportStepValidationConfig = {
  maxLengthName?: number;
  maxLengthText?: number;
  nodeNamesInImport?: string[];
  characterNamesInEpisode?: string[];
}

export enum EpisodeTextImportCommandKey {
  Goto = 'goto',
  Choice = 'choice',
  Location = 'location',
  Chat = 'chat',
}

export const EpisodeTextImportCommandTypes = {
  [EpisodeTextImportCommandKey.Goto]: [
    {
      pattern: /(#GOTO)[:\s]+([A-Za-z0-9_]+)/i,
      matchValueGroup: 2,
      scope: [EpisodeTextColumnKey.Text, EpisodeTextColumnKey.Description],
    },
    {
      pattern: /(#->)(:)?[:\s]?([A-Za-z0-9_]+)/,
      matchValueGroup: 3,
      scope: [EpisodeTextColumnKey.Text, EpisodeTextColumnKey.Description],
    },
  ],
  [EpisodeTextImportCommandKey.Choice]: [
    {
      pattern: /(#CHOICE)[:\s]?/i,
      matchValueGroup: 0,
      scope: [EpisodeTextColumnKey.Description],
    },
    {
      pattern: /(#-\?)(:)?[:\s]?/,
      matchValueGroup: 0,
      scope: [EpisodeTextColumnKey.Description],
    },
  ],
  [EpisodeTextImportCommandKey.Location]: [
    {
      pattern: /(#LOCATION|#-L)[:\s]+([A-Za-z0-9_-]+)/i,
      matchValueGroup: 2,
      scope: [EpisodeTextColumnKey.Description],
    },
  ],
  [EpisodeTextImportCommandKey.Chat]: [
    {
      pattern: /(#CHAT|#-C)[:\s]?/i,
      matchValueGroup: 1,
      scope: [EpisodeTextColumnKey.Description],
    },
  ],
};

export type EpisodeTextImportCommandValue = Map<EpisodeTextColumnKey, string | number | undefined>;

export type EpisodeTextImportCommands = Map<EpisodeTextImportCommandKey, EpisodeTextImportCommandValue>;
