import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import {
  MemoryDTO, MemoryShowIn, MemoryType, MemoryValue,
} from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { stringOrNumberValuePattern } from 'components/pages/Book/MemoryBank/utils';
import { Character } from 'dorian-shared/types/character/Character';
import { BranchStepMemoryActionType } from '../../../../../dorian-shared/types/branch/BranchStep';
import { MemoryBankCheckValue } from './MemorySlotCompareForm';
import { MemorySlotCompareValueFormList } from './MemorySlotCompareValueFormList';
import { getShowInSelectOptions, getShowInSelectValue } from './utils';

type MemorySlotCompareValueFormProps = {
  valueInputValue: MemoryValue | MemoryShowIn[],
  stepIndex: number,
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  characters: Character[],
  memoryBankSlot?: MemoryDTO,
  inputName: string,
  isDisabled?: boolean,
  actionType?: BranchStepMemoryActionType | '',
}

export function MemorySlotCompareValueForm(props: MemorySlotCompareValueFormProps) {
  const {
    valueInputValue, stepIndex, onChangeValue, memoryBankSlot, characters, inputName,
    isDisabled, actionType,
  } = props;

  const isViewValue = actionType === BranchStepMemoryActionType.View;
  const isStringValue = memoryBankSlot?.type === MemoryType.String || memoryBankSlot?.type === MemoryType.Number;
  const isTextValue = isStringValue && !isViewValue;
  const isBooleanValue = memoryBankSlot?.type === MemoryType.Boolean;
  const isCharacterValue = memoryBankSlot?.type === MemoryType.Character;

  return (
    <Form.Group as={Row}>
      <Col>
        {isTextValue && (
          <Form.Control
            disabled={isDisabled}
            size="sm"
            type="text"
            placeholder="value"
            name={inputName}
            pattern={stringOrNumberValuePattern}
            value={valueInputValue}
            onChange={(event) => {
              onChangeValue([{
                name: 'value',
                value: event.target.value,
              }], stepIndex);
            }}
            required
          />
        )}

        {isBooleanValue && (
          <MemorySlotCompareValueFormList
            isDisabled={isDisabled}
            stepIndex={stepIndex}
            onChangeValue={onChangeValue}
            valueInputValue={valueInputValue as MemoryValue}
            inputName={inputName}
          >
            <option value="" disabled>[select]</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </MemorySlotCompareValueFormList>
        )}

        {isCharacterValue && (
          <MemorySlotCompareValueFormList
            isDisabled={isDisabled}
            stepIndex={stepIndex}
            onChangeValue={onChangeValue}
            valueInputValue={valueInputValue as MemoryValue}
            inputName={inputName}
          >
            <option value="" disabled>[select]</option>
            {characters.map((character) => (
              <option value={character.id} key={character.id}>
                {character.name}
              </option>
            ))}
          </MemorySlotCompareValueFormList>
        )}

        {isViewValue && (
          <>
            <Select
              className="react-select-container sm"
              classNamePrefix="react-select"
              isMulti
              closeMenuOnSelect={false}
              menuShouldBlockScroll
              backspaceRemovesValue
              tabSelectsValue={false}
              placeholder="Hide"
              options={getShowInSelectOptions()}
              value={getShowInSelectValue(valueInputValue as MemoryShowIn[])}
              onChange={(selectedOptions) => {
                const newValue = selectedOptions.length === 0
                  ? [MemoryShowIn.Hide]
                  : selectedOptions
                    .filter((v) => v.value !== MemoryShowIn.Hide)
                    .map((v) => v.value);
                onChangeValue([{
                  name: 'value',
                  value: newValue as MemoryShowIn[],
                }], stepIndex);
              }}
            />
            {/* Since the form doesn't support array values, we need to send it as a hidden string input */}
            {/* for handleSubmit() in AddBranch.js */}
            <input type="hidden" name={inputName} value={valueInputValue} />
          </>
        )}
      </Col>
    </Form.Group>
  );
}
